<template>
  <!--  -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="apply">新建</el-button>
      <el-button class="itemBtn btnColor" @click="modifybut">修改</el-button>
      <el-button class="itemBtn btnColor" @click="CancelPayment"
        >删除</el-button
      >

      <!-- <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <el-table-column
          min-width="120"
          label="轮播图标题"
          prop="title"
        ></el-table-column>
        <el-table-column
          min-width="60"
          label="排序"
          sortable
          prop="sort"
        ></el-table-column>
        <el-table-column
          min-width="120"
          label="跳转地址"
          prop="jumpUrl"
        ></el-table-column>

        <el-table-column min-width="220" label="图片">
          <template slot-scope="scope">
            <el-image
              style="width: 200px; height: 60px"
              :src="scope.row.fileUrl"
              lazy
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          min-width="120"
          label="文件名"
          prop="fileName"
        ></el-table-column>
        <el-table-column
          min-width="120"
          label="创建时间"
          prop="createTime"
        ></el-table-column>
        <!-- <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template> -->
      </el-table>
    </div>
    <!-- 弹窗 -->
    <div>
      <!-- //排序 -->
      <el-dialog
        title="自定义表头排序"
        :visible.sync="show_sortTableHeard"
        append-to-body
        width="40%"
        center
      >
        <div>
          <h5 style="margin: 0">上下拖动可以排序,需要显示的列请打钩√</h5>
          <div class="titles_dialog">
            <el-checkbox label="全选" v-model="allCheck" @change="checkAll">
            </el-checkbox>
            <div>
              <span>模板名称 : </span>
              <input class="mdTitName" type="text" v-model="sortName" />
            </div>
            <div v-if="tabelHeadeTitle">
              {{ selectLength }} / {{ tabelHeadeTitle.length || 0 }}
            </div>
          </div>
          <draggable v-model="tabelHeadeTitle">
            <transition-group>
              <div
                class="daraStyle"
                v-for="(element, index) in tabelHeadeTitle"
                :key="element.field"
              >
                <el-checkbox
                  class="rowChecks"
                  :label="element.id"
                  v-model="element.isShow"
                  @change="checktab(index, $event)"
                >
                  {{ element.name }}
                </el-checkbox>
                <div class="cloWidth">
                  <div class="cloLabel">列宽:</div>
                  <el-input
                    class="cloInput"
                    type="number"
                    v-model="element.width"
                  ></el-input>
                </div>
                <div class="daraNum">{{ element.sort }}</div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelSortHeard">取 消</el-button>
          <el-button @click="confirmOk" type="primary" style="margin-left: 50px"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- 新建 -->
      <el-dialog
        title="会员端首页宫格设置"
        :visible.sync="NewWarehouse"
        @close="WarehouseClose"
      >
        <div class="newForm">
          <el-form
            label-width="120px"
            ref="advertisement"
            :model="advertisement"
            :rules="rules"
            status-icon
          >
            <div class="queryItem1">
              <el-form-item label="标题:" prop="title">
                <el-input
                  placeholder="请输入"
                  v-model="advertisement.title"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="排序" prop="sort">
                <el-input-number
                  v-model="advertisement.sort"
                  controls-position="right"
                  :min="1"
                  :max="100000"
                ></el-input-number>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item
                class="dialogFormItems"
                prop="newsBannerUrlTypeId"
                label="跳转类型:"
              >
                <el-select
                  clearable
                  filterable
                  v-model="advertisement.newsBannerUrlTypeId"
                  placeholder="请选择"
                  @change="changeType"
                >
                  <el-option
                    v-for="item in jumpTypeList"
                    :key="item.typeId"
                    :label="item.title"
                    :value="item.typeId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="跳转链接:">
                <el-input
                  placeholder="请输入"
                  :disabled="isdiaabled"
                  v-model="advertisement.jumpUrl"
                ></el-input>
              </el-form-item>
            </div>
            <!-- 
            <div class="queryItem1">
              <el-form-item label="商品id:">
                <el-input
                  placeholder="请输入商品id,跳转商品详情必填"
                  v-model="advertisement.Goodsid"
                ></el-input>
              </el-form-item>
            </div> -->
            <div class="queryItem1">
              <el-form-item label="备注:">
                <el-input
                  placeholder="请输入"
                  v-model="advertisement.remarks"
                ></el-input>
              </el-form-item>
            </div>
            <div class="imgbox">
              <div style="margin-bottom: 4px">*上传图片(只能上传一张)</div>
              <el-upload
                action=""
                :http-request="UploadPictures"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileListImg"
                :limit="limitImg"
              >
                <i class="el-icon-plus" style="font-size: 13px"></i>
              </el-upload>

              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </el-form>
        </div>

        <div class="footerbox">
          <el-button class="seniorbut" @click="PaymentConfirmation"
            >保存</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";
import tool from "@/assets/js/tool";

export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    paging,
  },
  data() {
    return {
      time1: "",
      time2: "",
      FormData: {
        aaa: "",
      },
      value: "",
      queryData: {
        bannerType: "index_tab:banner_type", //start_page_banner:banner_type=启动页图片; index_banner:banner_type=首页轮播图; share_page_img:banner_type=分享海报; index_tab:banner_type=首页八宫格;
      },

      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "标题",
          field: "title",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },

        {
          name: "排序",
          field: "sort",
          sort: 2,
          width: "120",
          isShow: true,
          isTotal: false,
        },
        {
          name: "跳转地址",
          field: "jumpUrl",
          width: "160",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "文件url",
          field: "fileUrl",
          sort: 3,
          width: "160",
          isShow: true,
          isTotal: false,
        },
        {
          name: "文件名称",
          field: "fileName",
          sort: 4,
          width: "120",
          isShow: true,
          isTotal: false,
        },
        {
          name: "创建时间",
          field: "createTime",
          width: "160",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileListImg: [], //轮播图文件数据
      limitImg: 1,
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "lunbotu", //页面标识
      selectTableData: [], //选中的数据
      //===============
      pageNum_member: 1, //页码
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      /*       yunshuList: [], //运输方式列表
      shengheList: [], //审核状态列表
      qudaoList: [], //渠道列表
      qudaodailiList: [], //渠道代理列表 */
      NewWarehouse: false, //新建修改仓库弹窗
      pageType: "", //修改删除状态
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        newsBannerUrlTypeId: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "请选择广告排序值", trigger: "blur" },
        ],
      },
      advertisement: {
        fileName: "", //文件名称
        sort: "", //排序
        title: "", //广告标题
        id: "", //广告bannerid
        jumpUrl: "", //跳转地址
        newsBannerUrlTypeId: "",
        Goodsid: "",
        remarks: "",
      },
      dialogImageUrl: "",
      dialogVisible: false,

      jumpTypeList: [],
      isdiaabled: true,
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.getGroupFindFieldSort(); //获取用户自定义表头
    // this.myTableHeard = this.tabelHeadeTitle;

    this.endtime = tools.getCurrentDate(); //今天的时间;
    // this.endtime = tools.getFlexDate(1); //今天+1的时间;
    this.sarttime = tools.getFlexDate(-3); //三天前
    this.times = [this.sarttime, this.endtime];
  },
  mounted() {
    const that = this;
    this.getData();
  },
  watch: {},
  methods: {
    changeType(e) {
      console.log("e", e);
      this.advertisement.jumpUrl = "";
      if (e) {
        this.jumpTypeList.forEach((item) => {
          if (item.typeId == e) {
            this.advertisement.jumpUrl = item.jumpUrl || "";
            if (!item.jumpUrl) {
              this.isdiaabled = false;
            } else {
              this.isdiaabled = true;
            }
          }
        });
      }
    },
    //上传图片
    UploadPictures(param) {
      //console.log(param);
      const file = param.file;
      const fileName = file.name;
      const form = new FormData(); // 根据后台需求数据格式
      form.append("file", file); // 文件对象
      form.append("fileName", fileName); // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action

      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      this.$showLoading();
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            this.$hideLoading();
            this.$message.success(res.data.message || "操作成功");
            console.log(res);
            this.advertisement.fileName = res.data.result.fileName;
          }
        })
        .catch(() => {
          this.$hideLoading();
        });
    },
    handleRemove(file, fileList) {
      // console.log(file)
      // console.log(fileList)
      this.advertisement.fileName = "";
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //新建时间
    timebut(e) {
      console.log(e[0] + " " + "00:00:00");
    },
    //弹窗关闭
    WarehouseClose() {
      this.$refs.advertisement.resetFields();
      this.NewWarehouse = false;
      this.advertisement = {
        fileName: "", //文件名称
        sort: "", //排序
        title: "", //广告标题
        id: "", //广告bannerid
        jumpUrl: "", //跳转地址
        newsBannerUrlTypeId: "",
        Goodsid: "",
        remarks: "",
      };
      this.fileListImg = [];
    },
    //新建
    apply() {
      this.pageType = "add";
      this.NewWarehouse = true;
      this.getbannerUrlTypeList();
    },
    getbannerUrlTypeList() {
      // { urlType: "nei_bu:url_type" }
      Api.bannerUrlTypeList().then((res) => {
        this.jumpTypeList = res.data.result || [];
      });
    },
    //修改
    modifybut() {
      this.pageType = "modify";
      let selectTableData = this.selectTableData;

      this.getbannerUrlTypeList();

      if (selectTableData.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        this.NewWarehouse = true;
        this.dialogImageUrl = selectTableData[0].fileUrl;
        this.advertisement = {
          fileName: selectTableData[0].fileName, //文件名称
          sort: selectTableData[0].sort, //排序
          title: selectTableData[0].title, //广告标题
          id: selectTableData[0].bannerId, //广告bannerid
          jumpUrl: selectTableData[0].jumpUrl, //跳转地址
          newsBannerUrlTypeId: selectTableData[0].newsBannerUrlTypeId,
          Goodsid: "",
          remarks: selectTableData[0].remarks,
        };

        if (selectTableData[0].jumpUrl.indexOf("&numIid=") != -1) {
          let arrs = selectTableData[0].jumpUrl.split("&numIid=");
          console.log("arrs", arrs);
          this.advertisement.jumpUrl = arrs[0];
          this.advertisement.Goodsid = arrs[1];
        }

        this.fileListImg = [
          {
            name: selectTableData[0].fileName,
            url: selectTableData[0].fileUrl,
          },
        ];
      }
    },
    //新建修改保存
    PaymentConfirmation() {
      this.$refs.advertisement.validate((valid) => {
        if (valid == false) {
          return;
        } else {
          //新建
          // let advertisement = this.advertisement;

          if (!this.advertisement.fileName) {
            this.$message.warning("请先上传图片");

            return false;
          }
          let _this = this;

          if (this.advertisement.Goodsid) {
            this.advertisement.jumpUrl =
              this.advertisement.jumpUrl +
              "&numIid=" +
              this.advertisement.Goodsid;
          }

          let advertisement = {
            fileName: this.advertisement.fileName || "",
            sort: this.advertisement.sort || "",
            title: this.advertisement.title || "",
            id: this.advertisement.id || "",
            jumpUrl: this.advertisement.jumpUrl || "",
            newsBannerUrlTypeId: this.advertisement.newsBannerUrlTypeId || "",
            remarks: this.advertisement.remarks || "",
            //start_page_banner:banner_type=启动页图片; index_banner:banner_type=首页轮播图; share_page_img:banner_type=分享海报; index_tab:banner_type=首页八宫格;
            bannerType: "index_tab:banner_type",
          };
          let sign1 = tool.getSign(advertisement);
          advertisement.sign = sign1;

          if (this.pageType == "add") {
            Api.addAdverBanner(advertisement).then((res) => {
              console.log(res);
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                _this.getData();
                _this.WarehouseClose();
              }
            });
          }
          //修改
          else {
            Api.modifyAdverBanner(advertisement).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                _this.getData();
                _this.WarehouseClose();
              }
            });
          }
        }
      });
    },
    //删除
    CancelPayment() {
      let _this = this;
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        let bannerId = selectTableData[0].bannerId;
        let pram = {
          bannerId: bannerId,
        };
        let sign = tool.getSign(pram);
        pram.sign = sign;
        this.$confirm("是否确认删除", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        }).then(() => {
          Api.delAdvBanner(pram).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              _this.getData();
            }
          });
        });
      }
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      // 服务类型:==========> item_category_v1.service_status
      // 费项类型:==========> item_service_v1.direction_status
    },

    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      } else {
        this.screen();
      }
    },
    //全屏模式
    screen() {
      // if (screenfull.isEnabled) {
      //     screenfull.toggle(this.$refs.tableWrapper);
      // }
    },
    // 阻止F11默认事件
    keydown(event) {
      if (event.keyCode === 122) {
        event.preventDefault();
        event.returnValue = false;
      }
    },

    /**
     * 响应屏幕变化事件，给isFullscreen赋值，标识是否全屏
     * IE中bug: 调试器打开，且与页面不分离时 -- 无效
     * **/
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFullscreen = winFlag;
      } else {
        this.isFullscreen = winFlag || isFull;
      }
      //console.log('winFlag || isFull', winFlag, isFull)
      if (winFlag == true && isFull == true) {
        this.fullscreen = true;
        this.TableStyle = false;

        //console.log(this.tableHeigth)
      } else {
        this.fullscreen = false;
        this.TableStyle = true;
      }
    },

    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);

      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadurl;
        a.target = "_blank";
        a.setAttribute("download", "综合查询.xlsx"); //指定下载后的文件名，防跳转
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      console.log(btnListHeight);
      console.log("btnListHeight");
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 20; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData;
      formData.pageStart = this.pageNum;
      formData.pageTotal = this.size;
      Api.getAdvBannerList(formData).then((res) => {
        //console.log(res.data.result.data);
        if (res.data.status == "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },

    // 查询按钮
    queryBtn_ok() {
      //console.log(this.queryData);
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {};
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk() {
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //综合查询接口路径
        },
        {
          key: "groupName",
          val: this.pageGroupName,
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    // 合计
    /* getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },
 */
    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.imgbox {
  width: 100%;
  padding: 20px 5px;
  box-sizing: border-box;
}
.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
  }
}
.singleinp1 {
  min-width: 392px;
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}
.SingleChoicebox {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
.el-input-number {
  width: 100%;
  height: 30px;
  line-height: 30px;
}

/deep/ .el-table .cell {
  white-space: inherit;
  text-align: left;
}
</style>
